import React, { useState, useEffect } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  SubTitle,
  Input,
  Button,
  Field,
  Control,
  LoadingAnimation,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv, LeafletCard } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Current Location",
    link: "/where-am-i-location-latitude-longitude/",
  },
]
const seeAlsoArray = [
  "/ip-address",
  "/find-location-of-ip-address",
  "/screen-resolution",
  "/what-is-my-browser-version",
]

function GetlatitudeLongitude(props) {
  let [currentLocation, setcurrentLocation] = useState("")
  let [lat, setlat] = useState("")
  let [lon, setlon] = useState("")

  let [errorResult, seterrorResult] = useState("")

  let [ipAddress, setipAddress] = useState("")
  let [leaflet, setLeaflet] = useState(null)

  useEffect(() => {
    var RTCPeerConnection = window.RTCPeerConnection
    var peerConn = new RTCPeerConnection({
      iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
    })
    peerConn.createOffer({}).then(desc => peerConn.setLocalDescription(desc))
    peerConn.onicecandidate = e => {
      if (e.candidate == null) {
        setipAddress(
          /c=IN IP4 ([^\n]*)\n/.exec(peerConn.localDescription.sdp)[1]
        )
      }
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition)
    } else {
      seterrorResult("Geolocation is not supported by this browser.")
    }
  }, [])

  function showPosition(position) {
    setlat(position.coords.latitude)
    setlon(position.coords.longitude)
    setcurrentLocation(
      `Your Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`
    )
    import("../../../components/MapLeaflet/MapLeaflet")
      .then(module => {
        const MapLeaflet = module.default
        setLeaflet(
          <MapLeaflet
            latitude={position.coords.latitude}
            longitude={position.coords.longitude}
            ip={ipAddress}
          />
        )
      })
      .catch(error => console.log(error))
    seterrorResult("")
  }

  function latC(e) {
    setlat(e.target.value)
  }
  function lonC(e) {
    setlon(e.target.value)
  }

  function myCurrentLocation() {
    if (lat === "" || lon === "") {
      seterrorResult("Please enter latitude longitude to find location")
    } else {
      seterrorResult("")
      import("../../../components/MapLeaflet/MapLeaflet")
        .then(module => {
          const MapLeaflet = module.default
          setLeaflet(
            <MapLeaflet latitude={lat} longitude={lon} ip={ipAddress} />
          )
        })
        .catch(error => console.log(error))
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Where am I?"
        description="Do you wonder where are you? Need to know your current location, Querying about the latitude and the longitude of your current location on the map. This tool allows you to know your current location on the map."
        keywords={[
          "Where Am I ,current location,what is my location, whats my current location,find Location latitude longitude, my location, search lat lon, find location, find latitude longitude, my latitude longitude, search for places lat lon , search place latitude longitude, get lat lon , get location lat lon, get latitude longitude, place latitude longitude.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Search Current Location Latitue Longitude</Title>
        <SubTitle>Where am I? know your current location on the map</SubTitle>

        <strong className="">{errorResult}</strong>

        <FlexDiv displayDiv="flex" flexWrap="wrap">
          <Field className="marginLeft">
            <Control>
              <StaticButton>Latitude</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                className="borderedRight"
                type="number"
                value={lat}
                onChange={latC}
              />
            </Control>
          </Field>

          <Field className="marginLeft">
            <Control>
              <StaticButton>Longitude</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                className="borderedRight"
                type="number"
                value={lon}
                onChange={lonC}
              />
            </Control>
          </Field>

          <Button
            className="mb-3"
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={myCurrentLocation}
          >
            Find Location
          </Button>
        </FlexDiv>
        {leaflet ? <LeafletCard>{leaflet}</LeafletCard> : <LoadingAnimation />}
        <br />
        <p>{currentLocation}</p>
        <br />

        <p>
          Input Latitude and Longitude in the inputs above and click the button
          to find the current location.
        </p>
        <br />
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default GetlatitudeLongitude
